<template>
  <div class="bg">
    <div class="content">
      <div class="con">
        <div class="h1">
          <strong>{{ organizationName }}</strong>
        </div>
        <div class="h2">Healthcare ePass</div>
        <div class="h3">OTP Verification</div>
        <div class="f1">
          Enter the code from the SMS we sent to {{ phoneNumber }}.
        </div>
        <div class="otp">
          <input
            v-for="i in OTP_LENGTH"
            :key="`input-el-${i}`"
            :ref="addInputEls"
            :id="`input-el-${i}`"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            maxlength="1"
            @input="handleInputElsInput($event, i - 1)"
          />
          <!-- <input type="tel" value="1" />
          <input type="tel" value="2" />
          <input type="tel" value="3" />
          <input type="tel" />
          <input type="tel" />
          <input type="tel" /> -->
        </div>
        <div v-if="isShowRemainTime" class="f2">
          Resend verification code - {{ remainTime }} sec -
        </div>
        <div v-if="!isShowRemainTime" class="f2" @click.prevent="onResendOtp">
          <div @click.prevent="onResendOtp">Resend verification code</div>
          <div
            v-if="isShow24HourWarning"
            class="f2"
            @click.prevent="onResendOtp"
          >
            Please try again after 24 hours.
          </div>
        </div>

        <a href="#" class="bt" @click.prevent="onVerifyOtp">
          <span>Submit</span>
        </a>
      </div>
      <!-- <div class="results">
        <div>opt: {{ otpValueAfterJoin }}</div>
        <div>has empty: {{ isOtpValueSomeEmpty ? 'true' : 'false' }}</div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, onUnmounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  const router = useRouter()
  const store = useStore()
  const organizationId = computed(() => store.state.otp.organizationId)
  const organizationName = computed(() => store.state.otp.organizationName)
  const phoneNumber = computed(() => store.state.otp.phoneNumber)
  const getOtp = (postBody) => store.dispatch('otp/getOtp', postBody)
  const verifyOtp = (postBody) => store.dispatch('otp/verifyOtp', postBody)

  const remainTime = ref(60)
  const isShowRemainTime = ref(true)
  const isShow24HourWarning = ref(false)
  const OTP_LENGTH = ref(6)
  const otpValue = ref(Array.from({ length: OTP_LENGTH.value }, () => ''))

  const otpValueAfterJoin = computed(() => {
    return otpValue.value.join('').replace(/[^0-9]/g, '')
  })

  const isOtpValueSomeEmpty = computed(() => {
    return otpValue.value.some((v) => v !== 0 && !v)
  })

  let isInProgress = ref(false)

  const handleInputElsInput = (e, index) => {
    const val = e.target.value.replace(/[^0-9]/g, '')

    // 如果是刪除並且當前框為空，跳到前一個框
    if (val === '' && e.inputType === 'deleteContentBackward' && index > 0) {
      inputEls.value[index - 1].focus()
      updateOtpValue()
      return
    }

    if (!val || isNaN(val)) {
      e.target.value = ''
      updateOtpValue()
      return
    }
    let idx = 0
    let el = e.target
    if (val.length === 0) {
      updateOtpValue()
      return
    }
    if (isInProgress.value) return
    isInProgress.value = true
    if (val.length === 1) {
      el.value = val
      el = el?.nextElementSibling ?? null
    }
    if (val.length === 2) {
      el.value = val.substring(1, 2)
      el = el?.nextElementSibling ?? null
    }
    while (val.length > 2 && idx < val.length && !!el) {
      el.value = val.substring(idx, idx + 1)
      el = el?.nextElementSibling ?? null
      idx++
    }
    el?.focus()
    isInProgress.value = false
    updateOtpValue()
  }

  const inputEls = ref([])

  const updateOtpValue = () => {
    otpValue.value.forEach((v, i) => {
      otpValue.value[i] = inputEls.value[i].value
    })
  }

  const addInputEls = (el) => {
    if (!el) return // 檢查 el 是否為 null
    const alreadyIn = inputEls.value.findIndex((v) => v?.id === el?.id) > -1
    if (alreadyIn) return
    inputEls.value.push(el)
  }

  // const addInputEls = (el) => {
  //   const alreadyIn = inputEls.value.findIndex((v) => v.id === el.id) > -1
  //   if (alreadyIn) return
  //   inputEls.value.push(el)
  // }

  const onVerifyOtp = async () => {
    if (isOtpValueSomeEmpty.value) return
    const otp = otpValueAfterJoin.value
    const postBody = {
      phoneNumber: phoneNumber.value,
      otp: otp,
    }

    const result = await verifyOtp(postBody)
    if (result === true) {
      console.log('Verify OTP success')
      router.push('/verify-code/qrCode')
    } else {
      console.log('Verify OTP failed')
    }
    // router.push('/verify-code/qrCode')
  }

  const onResendOtp = async () => {
    const postBody = {
      organizationId: organizationId.value,
      phoneNumber: phoneNumber.value,
    }
    const result = await getOtp(postBody)
    if (result?.ok === true) {
      console.log('Resend OTP')
      alert('Resend OTP')
      // Reset countdown
      remainTime.value = 60
      isShowRemainTime.value = true
      localStorage.setItem(
        'otpCountdownEndTime',
        (Date.now() + 60000).toString()
      )
      startCountdown()
    } else {
      if (result?.error_message === 'Over limit') {
        isShow24HourWarning.value = true
      }
      // console.log(result)
      console.log('Resend OTP failed')
      // alert('Resend OTP failed')
    }
    console.log('Resend OTP')
  }

  // New countdown functionality
  const startCountdown = () => {
    const endTime = localStorage.getItem('otpCountdownEndTime')
    if (endTime) {
      const remaining = Math.max(
        0,
        Math.floor((parseInt(endTime) - Date.now()) / 1000)
      )
      remainTime.value = remaining
    } else {
      const newEndTime = Date.now() + 60000 // 60 seconds from now
      localStorage.setItem('otpCountdownEndTime', newEndTime.toString())
    }

    const timer = setInterval(() => {
      if (remainTime.value > 0) {
        remainTime.value--
      } else {
        clearInterval(timer)
        isShowRemainTime.value = false
        localStorage.removeItem('otpCountdownEndTime')
      }
    }, 1000)

    onUnmounted(() => {
      clearInterval(timer)
    })
  }

  onMounted(() => {
    let el = inputEls.value
    if (Array.isArray(el)) el = el[0]
    el?.focus()

    startCountdown()
  })
</script>

<style lang="less" scoped>
  @charset "utf-8";
  body {
    font-family: Arial, sans-serif;
    padding: 0;
    margin: 0;
  }
  a,
  img {
    outline: none;
  }
  input,
  textarea {
    outline: none;
  }
  .bg {
    position: relative;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content {
    position: relative;
    width: 490px;
    margin: 0 auto;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content::before,
  .content::after {
    position: relative;
    width: 100%;
    content: '';
    display: block;
  }
  .content::before {
    padding-bottom: 6.66%;
  }
  .content::after {
    padding-bottom: 9.33%;
  }
  .con {
    position: relative;
    width: 85.3%;
    margin: 0 auto;
    text-align: center;
    color: #595959;
  }
  .h1,
  .h2,
  .h3 {
    position: relative;
    display: block;
  }
  .h1 {
    font-size: 40px;
    font-weight: bolder;
    margin: 0 auto 14px;
  }
  .h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .h3 {
    font-size: 24px;
    color: #505050;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .f1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 95px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto 33px;
  }
  .f1 span {
    display: inline-block;
  }
  .f2 {
    font-size: 14px;
    line-height: 18px;
    min-height: 93px;
    text-align: left;
    color: #ed1d24;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 93px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f4 {
    font-size: 16px;
    line-height: 25px;
    color: #575555;
    text-align: left;
    margin: 30px auto 0;
  }
  .telnb {
    position: relative;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    margin: 0 auto 10px;
    font-family: Arial, sans-serif;
    font-size: 20px;
    line-height: 50px;
    color: #575555;
    border: 1px solid #14224a;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .otp {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .otp input {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin: 0 2.1875% 10px 0;
    font-family: Arial, sans-serif;
    font-size: 32px;
    line-height: 50px;
    color: #575555;
    border: 1px solid #14224a;
    text-align: center;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .otp input:last-child {
    margin: 0 0 10px 0;
  }
  input:focus {
    border: 1px solid #ffa200;
  }
  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }
  :-ms-input-placeholder {
    color: #c4c4c4;
  }
  ::placeholder {
    color: #c4c4c4;
  }
  .blu {
    color: #2f80ed;
  }
  .bt {
    position: relative;
    width: 100%;
    padding-bottom: 15.625%;
    border-radius: 10px;
    display: block;
    background-color: #8b2331;
    margin: 0 auto 10px;
    transition: all 0.5s;
  }
  .bt:hover {
    background-color: #b52639;
  }
  .bt span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  .qr {
    position: relative;
    width: 74.375%;
    margin: -7px auto 15px;
    display: block;
  }
  .qr img {
    position: relative;
    width: 100%;
    display: block;
  }
  .name {
    position: relative;
    width: 100%;
    padding-bottom: 16.5625%;
    border-radius: 5px;
    margin: 15px auto 50px;
  }
  .name span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #595959;
  }
  @media only screen and (max-width: 500px) {
    .content {
      width: 100%;
    }
  }
  @media only screen and (max-width: 450px) {
    .h1 {
      font-size: 8.88vw;
      margin: 0 auto 3.11vw;
    }
    .h2 {
      font-size: 6.6vw;
      margin: 0 auto 7.7vw;
    }
    .h3 {
      font-size: 5.33vw;
      margin: 0 auto 7.7vw;
    }
    .f1 {
      font-size: 4.4vw;
      line-height: 5.5vw;
      min-height: 21.1vw;
      padding: 0 0.6vw;
      margin: 0 auto 7.3vw;
    }
    .f2 {
      font-size: 3.11vw;
      line-height: 4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.11vw;
      line-height: 4.4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f4 {
      font-size: 3.55vw;
      line-height: 5.5vw;
      margin: 6.6vw auto 0;
    }
    .telnb {
      padding: 0 2.2vw;
      border-radius: 1.7vw;
      margin: 0 auto 2.2vw;
      font-size: 4.4vw;
      line-height: 11.11vw;
    }
    .otp {
      width: 120%;
      left: -10%;
    }
    .otp input {
      width: 11.11vw;
      height: 11.11vw;
      border-radius: 1.7vw;
      margin: 0 2.1875% 2.2vw 0;
      font-size: 7.11vw;
      line-height: 11.11vw;
    }
    .otp input:last-child {
      margin: 0 0 2.2vw 0;
    }
    .bt {
      border-radius: 2.2vw;
      margin: 0 auto 2.2vw;
    }
    .bt span {
      font-size: 5.3vw;
    }
  }
  @media only screen and (max-width: 375px) {
    .h1 {
      font-size: 10.6vw;
      margin: 0 auto 3.72vw;
    }
    .h2 {
      font-size: 8vw;
      margin: 0 auto 9.33vw;
    }
    .h3 {
      font-size: 6.4vw;
      margin: 0 auto 9.33vw;
    }
    .f1 {
      font-size: 5.33vw;
      line-height: 6.6vw;
      min-height: 25.33vw;
      padding: 0 0.8vw;
      margin: 0 auto 8.8vw;
    }
    .f2 {
      font-size: 3.72vw;
      line-height: 4.8vw;
      min-height: 24.8vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.72vw;
      line-height: 5.33vw;
      min-height: 24.8vw;
      padding: 0 0.8vw;
    }
    .f4 {
      font-size: 4.26vw;
      line-height: 6.6vw;
      margin: 8vw auto 0;
    }
    .telnb {
      padding: 0 2.6vw;
      border-radius: 2.13vw;
      margin: 0 auto 2.6vw;
      font-size: 5.33vw;
      line-height: 13.33vw;
    }
    .otp {
      width: 100%;
      left: 0;
    }
    .otp input {
      width: 13.33vw;
      height: 13.33vw;
      border-radius: 2.66vw;
      margin: 0 2.4vw 2.6vw 0;
      font-size: 8.53vw;
      line-height: 13.33vw;
    }
    .otp input:last-child {
      margin: 0 0 2.6vw 0;
    }
    .bt span {
      font-size: 6.4vw;
    }
  }
</style>
